// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
// import 'lib/adminkit'
import intlTelInput from 'intl-tel-input';

// Load Vendored JS
// Make jQuery AVAILABLE EVEN INSIDE Views FOLDER
global.$ = require("jquery")
// require("jquery")
require("jquery-ui")
require("@fortawesome/fontawesome-free/js/all")
require("../lib/generic_methods.js");

// import flatpickr from "flatpickr"
// import "flatpickr/dist/flatpickr.min.css"

var moment = require('moment')

import daterangepicker from "daterangepicker"

// include moment in global and window scope (so you can access it globally)
global.moment = moment;
window.moment = moment;
window.intlTelInput = intlTelInput;

Rails.start()
// Turbolinks.start() // Disabling turbolinks due to js issues
ActiveStorage.start()


$(document).on('turbolinks:load', function() {
  // Fade out alerts
  // $('button.delete').parent().delay(10000).fadeOut('slow');

  // Close flash notices on button click
  $('button.delete').click(function() {
    $(this).parent().hide();
  });

  // flatpickr(".datepicker", {});

  // $('input[name=daterangepicker]').daterangepicker({
  //   opens: 'left'
  // }, function(start, end, label) {
  //   console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
  // });
});


//Activate all tooltips for modals
$(document).on('ajax:success', function(event) {
  // Feature library icons update
  feather.replace();

  // Tooltips on js.erb(s)
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});
